<script setup>
	import { clone } from '@/composables/helpers/clone.js';
	const props = defineProps({
		tour: {
			type: Object,
			required: true,
			default: () => ({}),
		},
	});
	const { tour } = toRefs(props);

	const blurb = computed(() => {
		return tour?.value?.content?.data?.blurb || '';
	});

	const filters = computed(() => {
		let filtersObject = tour?.value?.content?.data?.filters || {};
		let cloned = clone(filtersObject);
		let architectureAndStyles = cloned?.architectureAndStyles || [];
		if (typeof architectureAndStyles === 'string') {
			cloned.architectureAndStyles = [architectureAndStyles];
		}

		return cloned;
	});

	const whereToMeet = computed(() => {
		return tour?.value?.content?.data?.whereToMeet || '';
	});
</script>

<template>
	<section class="block block-tour-details" tabindex="-1">
		<div class="container">
			<div v-html="blurb" class="red-box rich-text"></div>
			<div class="white-box">
				<div class="row">
					<div v-if="filters?.duration" class="cell">
						<h6>Duration</h6>
						<MessLink :href="`/city-tours?duration=${filters?.duration}`" class="link">
							{{ filters?.duration }}
						</MessLink>
					</div>
					<div v-if="filters?.siteType && filters?.siteType !== 'None'" class="cell">
						<h6>Site Type</h6>
						<MessLink :href="`/city-tours?site-type=${filters?.siteType}`" class="link">
							{{ filters?.siteType }}
						</MessLink>
					</div>
					<div v-if="filters?.location && filters?.location !== 'None'" class="cell">
						<h6>Location</h6>
						<MessLink :href="`/city-tours?location=${filters?.location}`" class="link">
							{{ filters?.location }}
						</MessLink>
					</div>
				</div>
				<div v-if="filters?.architectsAndStyles?.length" class="row">
					<div class="cell">
						<h6>Architects and Styles</h6>
						<ul class="link-list">
							<li v-for="(item, index) in filters?.architectsAndStyles.sort()" :key="index">
								<MessLink :href="`/city-tours?architects-and-styles=${item}`" class="link">
									{{ item }}
								</MessLink>
								<span v-if="index < filters?.architectsAndStyles?.length - 1">,</span>
							</li>
						</ul>
					</div>
				</div>
				<div v-if="whereToMeet?.length" class="row">
					<div class="cell">
						<h6>Meet</h6>
						<div v-html="whereToMeet" class="rich-text"></div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss">
	.block-tour-details {
		background-color: var(--gray-100);

		.container {
			max-width: 1130px;

			@media (min-width: $mobile) {
				display: flex;
				justify-content: flex-end;
				align-items: flex-start;
			}
		}

		.red-box {
			margin-bottom: 0;
			padding: 30px;

			@media (min-width: $mobile) {
				max-width: 575px;
				flex: 1 1 50%;
				padding: 45px;
			}
		}

		.white-box {
			padding: 30px;
			background-color: var(--white);

			@media (min-width: $mobile) {
				max-width: 555px;
				flex: 1 1 50%;
				margin-top: 150px;
				padding: 45px;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: -10px;
				margin-bottom: 20px;

				@media (min-width: $mobile) {
					margin-bottom: 30px;
				}

				&:last-of-type {
					margin-bottom: 0;
				}

				.cell {
					margin: 10px;
				}

				.link-list {
					display: flex;
					flex-wrap: wrap;

					> li {
						margin-right: 5px;
					}
				}
			}
		}

		.red-box,
		.red-box * {
			background-color: var(--red);
			color: var(--white);
			font-size: calc(24 / var(--base-fs) * 1rem);
			font-weight: 700;
			line-height: 1.4;
		}

		.white-box {
		}
	}
</style>
